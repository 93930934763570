import React from "react";

const spaceTop = {
    marginTop: "-7px"
  };

const cardTour = {
    display: "flex",
    flexDirection: "column",
    textAlign: "left"
};

const cardCenter = {
    textAlign: "center"
};

const titleCard = {
    fontWeight: "bolder",
    textAlign: "center"
};

export const steps = [
    {
        selector: ".button_filter", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Filtrar</p>
                <p style={spaceTop}>Filtra os meses que serão apresentados no Painel.</p>
            </div>
        )
    },
    {
        selector: ".button_options",
        content: () => (
            <div>
                <p><strong>Histórico:</strong> Detalha a alteração realizada, indicando o valor antigo para o que foi atualizado, na data em que ocorreu.</p>
                <p><strong>Porcentagem:</strong> Valor, em porcentagem, de cada item da DRE em relação ao faturamento. Os itens abaixo de Faturamento Líquido Total são divididos por ele, já os itens acima são divididos pelo Faturamento Bruto Total.</p>
            </div>
        )
    },
    {
        selector: ".button_exportar", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Exportar</p>
                <p style={spaceTop}>Para imprimir ou salvar o relatório em PDF ou Excel.</p>
            </div>
        )
    },
    {
        selector: ".icon_projetada", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>DRE Projetada</p>
                <p style={spaceTop}>Permite a criação de uma DRE projetada para o mês seguinte.</p>
            </div>
        )
    },
    {
        selector: ".linha_1",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Faturamento Bruto Total</p>
                <p>Faturamento Bruto de Itens</p>
                <p>+</p>
                <p style={spaceTop}>Faturamento de Serviços (Bilheteria e Eventos)</p>
            </div>
        )
    },
    {
        selector: ".linha_2", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Faturamento Bruto de Itens</p>
                <p>Faturamento bruto das vendas provenientes do sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_3", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Devoluções e Cancelamentos</p>
                <p>Total das vendas canceladas e/ou devoluções provenientes do sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_4", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Descontos</p>
                <p>Total dos descontos realizados nas vendas provenientes do sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_5", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Fretes Aplicativos</p>
                <p>Total da taxa de entrega cobrada pelos aplicativos de delivery. Este valor deve ser inserido na DRE quando a receita do frete constar na receita proveniente do sistema frente de caixa e este frete não fazer parte dos recebimentos do restaurante.</p>
            </div>
        )
    },
    {
        selector: ".linha_6", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Comissões sobre Vendas</p>
                <p>Total das comissões sobre vendas proveniente do Conta Azul.</p>
            </div>
        )
    },
    {
        selector: ".linha_7", 
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Faturamento Líquido de Itens</p>
                <p>Faturamento Bruto de Itens -</p>
                <p style={spaceTop}>Devoluções e Cancelamentos -</p>
                <p style={spaceTop}>Frete Aplicativos -</p>
                <p style={spaceTop}>Comissões sobre Vendas -</p>
                <p style={spaceTop}>Descontos</p>
            </div>
        )
    },
    {
        selector: ".linha_8", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Faturamento de Serviços</p>
                <p>Faturamento de serviços em bilheteria e eventos.</p>
            </div>
        )
    },
    {
        selector: ".linha_9", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Faturamento Líquido Total</p>
                <p>Faturamento Líquido de Itens</p>
                <p style={spaceTop}>+</p>
                <p style={spaceTop}>Faturamento de Serviços</p>
            </div>
        )
    },
    {
        selector: ".linha_10", 
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Taxa de Impostos sobre Vendas</p>
                <p>Faturamento de Serviços +</p>
                <p style={spaceTop}>Taxas de Cartão e Apps de Delivery +</p>
                <p style={spaceTop}>Impostos sobre Vendas +</p>
                <p style={spaceTop}>Devoluções de Vendas +</p>
                <p style={spaceTop}>Taxa de Marketing +</p>
                <p style={spaceTop}>Royalties +</p>
                <p style={spaceTop}>Taxa Administrativa</p>
            </div>
            
        )
    },
    {
        selector: ".linha_11", 
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Taxas de Cartão e Apps de Delivery</p>
                <p style={spaceTop}>Taxas de Apps de Delivery +</p>
                <p style={spaceTop}>Taxas de Adquirentes +</p>
                <p style={spaceTop}>Taxas de Vouchers de Alimentação</p>
            </div>
        )
    },
    {
        selector: ".linha_12", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Impostos sobre Vendas</p>
                <p style={spaceTop}>Soma de todos os lançamentos do Conta Azul que se caracterizam como impostos sobre vendas.</p>
                <br></br>
                <i>O valor, quando provisionado, é calculado como a divisão do valor total do “Impostos sobre vendas” dos últimos três meses pelo valor total do “Faturamento Bruto Total” dos últimos três meses.</i>
            </div>
        )
    },
    {
        selector: ".linha_13", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Devoluções de Vendas</p>
                <p style={spaceTop}>Soma de todos os lançamentos do Conta Azul que se caracterizam como devoluções sobre vendas.</p>
            </div>
        )
    },
    {
        selector: ".linha_14", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Taxas de Marketing</p>
                <p style={spaceTop}>Soma de todos os lançamentos do Conta Azul que se caracterizam como taxa de marketing.</p>
                <br></br>
                <i>O valor, quando provisionado, é calculado como a divisão do valor total da “Taxa de Marketing” dos últimos três meses pelo valor total do “Faturamento Líquido Total” dos últimos três meses.</i>
            </div>
        )
    },
    {
        selector: ".linha_15", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Royalties</p>
                <p style={spaceTop}>Soma de todos os lançamentos do Conta Azul que se caracterizam como royalties.</p>
                <i>O valor, quando provisionado, é calculado como a divisão do valor total dos “Royalties” dos últimos três meses pelo valor total do “Faturamento Líquido Total” dos últimos três meses.</i>
            </div>
        )
    },
    {
        selector: ".linha_16", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Taxa Administrativa</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como Taxa Administrativa.</p>
            </div>
        )
    },
    {
        selector: ".linha_17", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Markup</p>
                <p>Faturamento Líquido dos Itens</p>
                <p style={spaceTop}>+</p>
                <p style={spaceTop}>CMV</p>
                <i>Indica por quantas vezes, em média, o produto foi vendido com relação ao seu custo</i>
            </div>
        )
    },
    {
        selector: ".linha_18",
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>CMV (Custo de Mercadoria Vendida)</p>
                <p>Insumos +</p>
                <p style={spaceTop}>Embalagens +</p>
                <p style={spaceTop}>Produtos para Comercialização</p>
            </div>
        )
    },
    {
        selector: ".linha_19", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>CMV em porcentagem</p>
                <p>Faturamento Líquido de Itens ÷ CMV</p>
                <i>Indica, em percentual, quanto da receita total do restaurante é gasta com insumos, embalagens e produtos para comercialização.</i>
            </div>
        )
    },
    {
        selector: ".linha_20", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Insumos</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como insumos.</p>
            </div>
        )
    },
    {
        selector: ".linha_21", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Embalagens</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como embalagens.</p>
            </div>
        )
    },
    {
        selector: ".linha_22", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Produtos para Comercialização</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como produtos para comercialização.</p>
            </div>
        )
    },
    {
        selector: ".linha_23", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Despesas com Entregas</p>
                <p>Combustível e Entregas</p>
                <p style={spaceTop}>+</p>
                <p style={spaceTop}>Valor do Frete da Entrega Parceira</p>
            </div>
        )
    },
    {
        selector: ".linha_24", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Despesas com Entregas em porcentagem</p>
                <p>Despesas com Entregas</p>
                <p style={spaceTop}>÷</p>
                <p style={spaceTop}>Faturamento Líquido de Itens</p>
                <i>Indica, em percentual, quanto da receita total do restaurante é gasta com a logística de entregas.</i>
            </div>
        )
    },
    {
        selector: ".linha_25", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Combustível e Entregas</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como combustível e entregas.</p>
            </div>
        )
    },
    {
        selector: ".linha_26", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Valor do Frete da Entrega Parceira</p>
                <p>Total da taxa de Entrega Parceira, cobrada pelo Ifood.</p>
            </div>
        )
    },
    {
        selector: ".linha_27", 
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Custos Variáveis</p>
                <p>Despesas com Entregas +</p>
                <p style={spaceTop}>CMV +</p>
                <p style={spaceTop}>Taxas e Impostos sobre Vendas</p>
            </div>
        )
    },
    {
        selector: ".linha_28", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Custos Variáveis em porcentagem</p>
                <p>Custos Variáveis</p>
                <p style={spaceTop}>÷</p>
                <p style={spaceTop}>Faturamento Líquido Total</p>
                <i>Os custos variáveis são aqueles que variam proporcionalmente ao volume de vendas do restaurante.</i>
            </div>
        )
    },
    {
        selector: ".linha_29", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Margem de contribuição</p>
                <p>Faturamento Líquido Total</p>
                <p style={spaceTop}>-</p>
                <p style={spaceTop}>Custos Variáveis</p>
                <i>A Margem de Contribuição indica o valor que sobra das vendas após a dedução dos custos variáveis. O valor da margem de contribuição é utilizado para custear as despesas fixas e gerar o lucro do restaurante.</i>
            </div>
        )
    },
    {
        selector: ".linha_30", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Margem de contribuição em porcentagem</p>
                <p>Margem de Contribuição</p>
                <p style={spaceTop}>÷</p>
                <p style={spaceTop}>Faturamento Líquido de Itens</p>
            </div>
        )
    },
    {
        selector: ".linha_31", 
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Custos Fixos</p>
                <p style={spaceTop}>Despesas com Pessoal +</p>
                <p style={spaceTop}>Despesas com Vendas e Marketing +</p>
                <p style={spaceTop}>Despesas Operacionais e Administrativas +</p>
                <p style={spaceTop}>Despesas a Identificar</p>
                <i>Os custos fixos são aqueles que não variam de acordo com o volume de vendas do restaurante no curto prazo.</i>
            </div>
        )
    },
    {
        selector: ".linha_32",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Custos Fixos em porcentagem</p> 
                <p>Custos Fixos</p>
                <p style={spaceTop}>÷</p>
                <p style={spaceTop}>Faturamento Líquido Total</p>
            </div>
        )
    },
    {
        selector: ".linha_33", 
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Despesas com Pessoal</p>
                <p>Salários +</p>
                <p style={spaceTop}>Bonificações +</p>
                <p style={spaceTop}>Impostos Sobre Salários +</p>
                <p style={spaceTop}>Vale Alimentação +</p>
                <p style={spaceTop}>Vale Transporte +</p>
                <p style={spaceTop}>Rescisões +</p>
                <p style={spaceTop}>Freelancers e Terceirizados +</p>
                <p style={spaceTop}>Pró-Labore e Despesas de Sócios +</p>
                <p style={spaceTop}>Outros Encargos com Funcionários +</p>
                <p style={spaceTop}>Férias +</p>
                <p style={spaceTop}>13º Salário +</p>
                <p style={spaceTop}>Músicos</p>
            </div>           
        )
    },
    {
        selector: ".linha_34", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Salários</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como salários.</p>
            </div>
        )
    },
    {
        selector: ".linha_35", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Bonificações</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como bonificações.</p>
            </div>
        )
    },
    {
        selector: ".linha_36", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Impostos sobre Salários</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como impostos sobre salários.</p>
            </div>
        )
    },
    {
        selector: ".linha_37", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Vale Alimentação</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como vale alimentação.</p>
            </div>
        )
    },
    {
        selector: ".linha_38", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Vale Transporte</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como vale transporte.</p>
            </div>
        )
    },
    {
        selector: ".linha_39", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Rescisões</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como rescisões.</p>
            </div>
        )
    },
    {
        selector: ".linha_40", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Freelancers e Terceirizados</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como freelancers e terceirizados.</p>
            </div>
        )
    },
    {
        selector: ".linha_41", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Pró-labore e Despesas de Sócios</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como pró-labore e despesas de sócios.</p>
            </div>
        )
    },
    {
        selector: ".linha_42", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Outros Encargos com Funcionários</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como outros encargos com funcionários.</p>
            </div>
        )
    },
    {
        selector: ".linha_43", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Férias</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como férias.</p>
            </div>
        )
    },
    {
        selector: ".linha_44", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>13º Salário</p>
                <p>(Salários + Impostos Sobre Salários) ÷ 12</p>
            </div>
        )
    },
    {
        selector: ".linha_45", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Músicos</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como músicos.</p>
            </div>
        )
    },
    {
        selector: ".linha_46", 
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Despesas com Vendas e Marketing</p>
                <p>Despesas com Marketing +</p>
                <p style={spaceTop}>Endomarketing +</p>
                <p style={spaceTop}>Pacote Anúncios Ifood</p>
            </div>
        )
    },
    {
        selector: ".linha_47", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Despesas com Marketing</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como despesas com marketing.</p>
            </div>
        )
    },
    {
        selector: ".linha_48", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Endomarketing</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como endomarketing.</p>
            </div>
        )
    },
    {
        selector: ".linha_49",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Pacote Anúncios Ifood</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como pacotes anúncios Ifood.</p>
            </div>
        )
    },
    {
        selector: ".linha_50", 
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Despesas Operacionais e Administrativas</p>
                <p>Aluguel e Condomínio +</p>
                <p style={spaceTop}>Água e Energia +</p>
                <p style={spaceTop}>Gás de Cozinha +</p>
                <p style={spaceTop}>Aluguel de Equipamentos +</p>
                <p style={spaceTop}>Segurança, Telefonia e Internet +</p>
                <p style={spaceTop}>Softwares e Terceirizados +</p>
                <p style={spaceTop}>Limpeza e Higiene +</p>
                <p style={spaceTop}>Manutenção Predial e de Equipamentos +</p>
                <p style={spaceTop}>Transporte +</p>
                <p style={spaceTop}>Materiais de Escritório +</p>
                <p style={spaceTop}>Tarifas Bancárias +</p>
                <p style={spaceTop}>Outras Despesas Administrativas +</p>
                <p style={spaceTop}>Despesas a Identificar</p>
            </div>
            
        )
    },
    {
        selector: ".linha_51", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Aluguel e Condomínio</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como aluguel e condomínio.</p>
            </div>
        )
    },
    {
        selector: ".linha_52", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Água e Energia</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como água e energia.</p>
            </div>
        )
    },
    {
        selector: ".linha_53", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Gás de Cozinha</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como gás de cozinha.</p>
            </div>
        )
    },
    {
        selector: ".linha_54", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Aluguel de Equipamentos</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como aluguel de equipamentos.</p>
            </div>
        )
    },
    {
        selector: ".linha_55", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Segurança, Telefonia e Internet</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como segurança, telefonia e internet.</p>
            </div>
        )
    },
    {
        selector: ".linha_56", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Softwares e Terceirizados</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como softwares e terceirizados.</p>
            </div>
        )
    },
    {
        selector: ".linha_57", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Limpeza e Higiene</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como limpeza e higiene.</p>
            </div>
        )
    },
    {
        selector: ".linha_58", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Manutenção Predial e de Equipamentos</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como manutenção predial e de equipamentos.</p>
            </div>
        )
    },
    {
        selector: ".linha_59", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Transporte</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como transporte.</p>
            </div>
        )
    },
    {
        selector: ".linha_60", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Materiais de Escritório</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como materiais de escritório.</p>
            </div>
        )
    },
    {
        selector: ".linha_61", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Tarifas Bancárias</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como tarifas bancárias.</p>
            </div>
        )
    },
    {
        selector: ".linha_62", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Outras Despesas Administrativas</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como outras despesas administrativas.</p>
            </div>
        )
    },
    {
        selector: ".linha_63", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Despesas a Identificar</p>
                <p>Soma de todos os subitens dele, isto é, “Despesas a Identificar”</p>
            </div>
        )
    },
    {
        selector: ".linha_64", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Despesas a Identificar</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como outras despesas a identificar.</p>
            </div>
        )
    },
    {
        selector: ".linha_65", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Lucro/Prejuízo Operacional</p>
                <p>Margem de contribuição</p>
                <p style={spaceTop}>-</p>
                <p style={spaceTop}>Custos Fixos</p>
                <i>Indica o lucro operacional do restaurante que é representado pelo resultado final das receitas operacionais e despesas operacionais.</i>
            </div>
        )
    },
    {
        selector: ".linha_66", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Lucro ou Prejuízo Operacional/Faturamento Líquido</p>
                <p>Lucro/Prejuízo Operacional</p>
                <p style={spaceTop}>÷</p>
                <p style={spaceTop}>Faturamento Líquido Total</p>
                <i>Indica a margem operacional do restaurante que é representada pela relação entre o lucro operacional e o faturamento bruto.</i>
            </div>
        )
    },
    {
        selector: ".linha_67", 
        content: () => (
            <div style={cardCenter}>
            <p style={titleCard}>Ponto de Equilíbrio</p>
            <p>(Margem de Contribuição * Fat. Líquido de Itens)</p>
            <p style={spaceTop}>÷</p>
            <p style={spaceTop}>Custos Fixos</p>
            <i>O Ponto de Equilíbrio indica o valor de venda para que o resultado operacional seja zero (nem lucro e nem prejuízo).</i>
        </div>
        )
    },
    {
        selector: ".linha_68", 
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Investimentos</p>
                <p style={spaceTop}>Inves. em Equipamentos e Estrutura +</p>
                <p style={spaceTop}>Rendimento de Aplicações +</p>
                <p style={spaceTop}>Impostos sobre Aplicações</p>
            </div>
        )
    },
    {
        selector: ".linha_69", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Investimento em Equipamentos e Estrutura</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como investimento em equipamentos e estrutura.</p>
            </div>
        )
    },
    {
        selector: ".linha_70", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Rendimento de Aplicações</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como rendimento de aplicações.</p>
            </div>
        )
    },
    {
        selector: ".linha_71", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Impostos sobre Aplicações</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como impostos sobre aplicações.</p>
            </div>
        )
    },
    {
        selector: ".linha_72", 
        content: () => (
            <div style={cardCenter}>
            <p style={titleCard}>Lucro/Prejuízo Líquido</p>
            <p>Lucro/Prejuízo Operacional</p>
            <p style={spaceTop}>-</p>
            <p style={spaceTop}>Investimentos</p>
            </div>
        )
    },
    {
        selector: ".linha_73", 
        content: () => (
            <div style={cardCenter}>
            <p style={titleCard}>Lucro ou Prejuízo Líquido/Faturamento Líquido</p>
            <p>Lucro/Prejuízo Líquido</p>
            <p style={spaceTop}>÷</p>
            <p style={spaceTop}>Faturamento Líquido Total</p>
            </div>
        )
    },
    {
        selector: ".linha_74", 
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Emprestimos, Aportes e Retiradas</p>
                <p style={spaceTop}>Empréstimos (Entrada) +</p>
                <p style={spaceTop}>Empréstimos (Saída) +</p>
                <p style={spaceTop}>Aportes +</p>
                <p style={spaceTop}>Retiradas de Lucro</p>
            </div>
            
        )
    },
    {
        selector: ".linha_75", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Empréstimos Recebidos</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como empréstimos recebidos.</p>
            </div>
        )
    },
    {
        selector: ".linha_76", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Empréstimos Fornecidos</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como empréstimos fornecidos.</p>
            </div>
        )
    },
    {
        selector: ".linha_77", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Aportes</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como aportes.</p>
            </div>
        )
    },
    {
        selector: ".linha_78", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Retiradas de Lucro</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como retiradas de lucro.</p>
            </div>
        )
    },
    {
        selector: ".linha_79", 
        content: () => (
            <div style={cardCenter}>
            <p style={titleCard}>Resultado Líquido de Emprestimos e aportes</p>
            <p>Lucro/Prejuízo Líquido</p>
            <p style={spaceTop}>-</p>
            <p style={spaceTop}>Empréstimos, Aportes e Retiradas</p>
            </div>
        )
    },
    {
        selector: ".linha_80", 
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Outras Entradas e Saídas</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como Outras Entradas e Saídas</p>
            </div>
            
        )
    },
    {
        selector: ".linha_81", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Transferências de Saída</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como transferências de saída.</p>
            </div>
        )
    },
    {
        selector: ".linha_82", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Transferências de Entrada</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como transferências de entrada.</p>
            </div>
        )
    },
    {
        selector: ".linha_83", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Saldo Inicial</p>
                <p>Saldo inicial proveniente do Conta Azul.</p>
            </div>
        )
    },
    {
        selector: ".linha_84",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Ajuste de Caixa</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como ajuste de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_85",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Reembolsos iFood</p>
                <p>Soma de todos os reembolsos e ressarcimentos recebidos do iFood</p>
            </div>
        )
    }
];

// OTHER DRE
export const otherDreSteps = [
    {
        selector: ".button_filter", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Filtrar</p>
                <p style={spaceTop}>Filtra os meses que serão apresentados na DRE.</p>
            </div>
        )
    },
    {
        selector: ".button_options",
        content: () => (
            <div>
                <p><strong>Histórico:</strong> Detalha a alteração realizada, indicando o valor antigo para o que foi atualizado, na data em que ocorreu.</p>
                <p><strong>Porcentagem:</strong> Valor, em porcentagem, de cada item da DRE em relação ao faturamento. Os itens abaixo de Faturamento Líquido Total são divididos por ele, já os itens acima são divididos pelo Faturamento Bruto Total.</p>
            </div>
        )
    },
    {
        selector: ".button_exportar", 
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Exportar</p>
                <p style={spaceTop}>Para imprimir ou salvar o relatório em PDF ou Excel.</p>
            </div>
        )
    },
    {
        selector: ".linha_1",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Faturamento Bruto Total</p>
                <p>Faturamento Bruto de Itens</p>
                <p>+</p>
                <p style={spaceTop}>Faturamento de Serviços</p>
            </div>
        )
    },
    {
        selector: ".linha_2",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Descontos</p>
                <p>Total dos descontos realizados nas vendas provenientes do sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_3",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Faturamento Bruto de Itens</p>
                <p>Faturamento bruto das vendas provenientes do sistema frente de caixa.</p>
            </div>
        )
    },
    {
        selector: ".linha_4",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Comissões sobre Vendas</p>
                <p>Total das comissões sobre vendas proveniente do Conta Azul.</p>
            </div>
        )
    },
    {
        selector: ".linha_5",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Taxas de Adquirência e Boletos</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como Taxas de Adquirência e Boletos.</p>
            </div>
        )
    },
    {
        selector: ".linha_6",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Faturamento Líquido de Itens</p>
                <p>Faturamento Bruto de Itens</p>
                <p>-</p>
                <p style={spaceTop}>Descontos</p>
            </div>
        )
    },
    {
        selector: ".linha_7",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Faturamento Serviços</p>
                <p>Faturamento proveniente de serviços em bilheteria e eventos.</p>
            </div>
        )
    },
    {
        selector: ".linha_8",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Impostos sobre Vendas</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como impostos sobre vendas.</p>
                <i>Os custos fixos são aqueles que não variam de acordo com o volume de vendas do restaurante no curto prazo.</i>
            </div>
        )
    },
    {
        selector: ".linha_9",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Devoluções de Vendas</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como devoluções sobre vendas.</p>
            </div>
        )
    },
    {
        selector: ".linha_10",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Custos do Serviços Prestado</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como serviços prestado.</p>
            </div>
        )
    },
    {
        selector: ".linha_11",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Margem de Contribuição</p>
                <p>Faturamento Líquido Total</p>
                <p>-</p>
                <p>Custos Variáveis</p>
                <i>A Margem de Contribuição indica o valor que sobra das vendas após a dedução dos custos variáveis. O valor da margem de contribuição é utilizado para custear as despesas fixas e gerar o lucro do estabelecimento.</i>
            </div>
        )
    },
    {
        selector: ".linha_12",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Margem de Contribuição em Porcentagem</p>
                <p>Margem de Contribuição</p>
                <p>÷</p>
                <p>Faturamento Líquido de Itens</p>
                <i>A Margem de Contribuição indica o valor que sobra das vendas após a dedução dos custos variáveis. O valor da margem de contribuição é utilizado para custear as despesas fixas e gerar o lucro do estabelecimento.</i>
            </div>
        )
    },
    {
        selector: ".linha_13",
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Custos Fixos</p>
                <p>Salários e Encargos +</p>
                <p style={spaceTop}>Despesas com Vendas e Marketing +</p>
                <p style={spaceTop}>Despesas Operacionais e Administrativas +</p>
                <p style={spaceTop}>Despesas a Identificar</p>
                <i>Os custos fixos são aqueles que não variam de acordo com o volume de vendas.</i>
            </div>
        )
    },
    {
        selector: ".linha_14",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Custos Fixos em porcentagem</p> 
                <p>Custos Fixos</p>
                <p style={spaceTop}>÷</p>
                <p style={spaceTop}>Receita Bruta</p>
            </div>
        )
    },
    {
        selector: ".linha_15",
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Despesas com Pessoal</p>
                <p>Salários +</p>
                <p style={spaceTop}>Bonificações +</p>
                <p style={spaceTop}>Impostos Sobre Salários +</p>
                <p style={spaceTop}>Vale Alimentação +</p>
                <p style={spaceTop}>Vale Transporte +</p>
                <p style={spaceTop}>Rescisões +</p>
                <p style={spaceTop}>Freelancers e Terceirizados +</p>
                <p style={spaceTop}>Pró-Labore e Despesas de Sócios +</p>
                <p style={spaceTop}>Outros Encargos com Funcionários +</p>
                <p style={spaceTop}>Férias +</p>
                <p style={spaceTop}>13º Salário</p>
            </div>   
        )
    },
    {
        selector: ".linha_16",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Salários</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como salários.</p>
            </div>
        )
    },
    {
        selector: ".linha_17",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Impostos sobre Salários</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como impostos sobre salários.</p>
            </div>
        )
    },
    {
        selector: ".linha_18",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Vale Alimentação</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como vale alimentação.</p>
            </div>
        )
    },
    {
        selector: ".linha_19",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Vale Transporte</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como vale transporte.</p>
            </div>
        )
    },
    {
        selector: ".linha_20",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Rescisões</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como rescisões.</p>
            </div>
        )
    },
    {
        selector: ".linha_21",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Freelancers e Terceirizados</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como freelancers e terceirizados.</p>
            </div>
        )
    },
    {
        selector: ".linha_22",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Pró-labore e Despesas de Sócios</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como pró-labore e despesas de sócios.</p>
            </div>
        )
    },
    {
        selector: ".linha_23",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Férias</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como férias.</p>
            </div>
        )
    },
    {
        selector: ".linha_24",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Outros Encargos com Funcionários</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como outros encargos com funcionários.</p>
            </div>
        )
    },
    {
        selector: ".linha_25",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>13º Salário</p>
                <p>(Salários + Impostos Sobre Salários) ÷ 12</p>
            </div>
        )
    },
    {
        selector: ".linha_26",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Despesas com Marketing e Endomarketing</p>
                <p>Despesas com Marketing</p>
                <p style={spaceTop}>+</p>
                <p style={spaceTop}>Endomarketing</p>
            </div>
        )
    },
    {
        selector: ".linha_27",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Despesas com Marketing</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como despesas com marketing.</p>
            </div>
        )
    },
    {
        selector: ".linha_28",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Endomarketing</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como endomarketing.</p>
            </div>
        )
    },
    {
        selector: ".linha_29",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Pacote Anúncios Ifood</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como pacotes anúncios Ifood.</p>
            </div>
        )
    },
    {
        selector: ".linha_30",
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Despesas Operacionais e Administrativas</p>
                <p>Aluguel e Condomínio +</p>
                <p style={spaceTop}>Água e Energia +</p>
                <p style={spaceTop}>Gás de Cozinha +</p>
                <p style={spaceTop}>Aluguel de Equipamentos +</p>
                <p style={spaceTop}>Segurança, Telefonia e Internet +</p>
                <p style={spaceTop}>Limpeza e Higiene +</p>
                <p style={spaceTop}>Manutenção Predial e de Equipamentos +</p>
                <p style={spaceTop}>Transporte +</p>
                <p style={spaceTop}>Transporte +</p>
                <p style={spaceTop}>Materiais de Escritório +</p>
                <p style={spaceTop}>Tarifas Bancárias +</p>
                <p style={spaceTop}>Outras Despesas Administrativas</p>
            </div>
        )
    },
    {
        selector: ".linha_31",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Aluguel e Condomínio</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como aluguel e condomínio.</p>
            </div>
        )
    },
    {
        selector: ".linha_32",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Água e Energia</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como água e energia.</p>
            </div>
        )
    },
    {
        selector: ".linha_33",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Segurança, Telefonia e Internet</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como segurança, telefonia e internet.</p>
            </div>
        )
    },
    {
        selector: ".linha_34",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Softwares e Terceirizados</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como softwares e terceirizados.</p>
            </div>
        )
    },
    {
        selector: ".linha_35",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Limpeza e Higiene</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como limpeza e higiene.</p>
            </div>
        )
    },
    {
        selector: ".linha_36",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Materiais de Escritório</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como materiais de escritório.</p>
            </div>
        )
    },
    {
        selector: ".linha_37",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Tarifas Bancárias</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como Tarifas Bancárias.</p>
            </div>
        )
    },
    {
        selector: ".linha_38",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Outras Despesas Administrativas</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como outras despesas administrativas.</p>
            </div>
        )
    },
    {
        selector: ".linha_39",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Combustível e Entregas</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como combustível e entregas.</p>
            </div>
        )
    },
    {
        selector: ".linha_40",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Despesas a Identificar</p>
                <p>Soma de todos os subitens dele, isto é, “Despesas a Identificar”</p>
            </div>
        )
    },
    {
        selector: ".linha_41",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Despesas a Identificar</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como outras despesas a identificar.</p>
            </div>
        )
    },
    {
        selector: ".linha_42",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Lucro Operacional</p>
                <p>Margem de contribuição</p>
                <p style={spaceTop}>-</p>
                <p style={spaceTop}>Custos Fixos</p>
                <i>Indica o lucro operacional do estabelecimento que é representado pelo resultado final das receitas operacionais e despesas operacionais.</i>
            </div>
        )
    },
    {
        selector: ".linha_43",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Lucro/Prejuízo Operacional em porcentagem</p>
                <p>Margem de contribuição</p>
                <p style={spaceTop}>-</p>
                <p style={spaceTop}>Custos Fixos</p>
                <i>Indica o lucro operacional do restaurante que é representado pelo resultado final das receitas operacionais e despesas operacionais.</i>
            </div>
        )
    },
    {
        selector: ".linha_44",
        content: () => (
            <div style={cardCenter}>
            <p style={titleCard}>Ponto de Equilíbrio</p>
            <p>(Custos Fixos * Fat. Líquido de Itens)</p>
            <p style={spaceTop}>÷</p>
            <p style={spaceTop}>Margem de Contribuição</p>
            <i>O Ponto de Equilíbrio indica o valor de venda para que o resultado operacional seja zero (nem lucro e nem prejuízo).</i>
        </div>
        )
    },
    {
        selector: ".linha_45",
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Investimentos</p>
                <p style={spaceTop}>Inves. em Equipamentos e Estrutura +</p>
                <p style={spaceTop}>Rendimento de Aplicações +</p>
                <p style={spaceTop}>Impostos sobre Aplicações</p>
            </div>
        )
    },
    {
        selector: ".linha_46",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Investimento em Equipamentos e Estrutura</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como investimento em equipamentos e estrutura.</p>
            </div>
        )
    },
    {
        selector: ".linha_47",
        content: () => (
            <div style={cardCenter}>
            <p style={titleCard}>Lucro/Prejuízo Líquido</p>
            <p>Lucro/Prejuízo Operacional</p>
            <p style={spaceTop}>-</p>
            <p style={spaceTop}>Investimentos</p>
            </div>
        )
    },
    {
        selector: ".linha_48",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Empréstimos Recebidos</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como empréstimos recebidos.</p>
            </div>
        )
    },
    {
        selector: ".linha_49",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Empréstimos Fornecidos</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como empréstimos fornecidos.</p>
            </div>
        )
    },
    {
        selector: ".linha_50",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Aportes</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como aportes.</p>
            </div>
        )
    },
    {
        selector: ".linha_51",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Retiradas de Lucro</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como retiradas de lucro.</p>
            </div>
        )
    },
    {
        selector: ".linha_52",
        content: () => (
            <div style={cardTour}>
                <p style={titleCard}>Resultado Líquido de Empréstimos, Aportes e Retiradas</p>
                <p>Lucro/Prejuízo Líquido -</p>
                <p>Transferências de Saída -</p>
                <p>Transferências de Entrada</p>
            </div>
        )
    },
    {
        selector: ".linha_53",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Transferências de Saída</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como transferências de saída.</p>
            </div>
        )
    },
    {
        selector: ".linha_54",
        content: () => (
            <div style={cardCenter}>
                <p style={titleCard}>Transferências de Entrada</p>
                <p>Soma de todos os lançamentos do Conta Azul que se caracterizam como transferências de entrada.</p>
            </div>
        )
    },
];
