import React, {useEffect, useState}  from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import api from "../../../axios";
import {paperStyleFull} from "../../../shared/utils";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {
	changeOpenComponent
} from "../../../actions/StoreActions";
import {useDispatch, useSelector} from "react-redux";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import RowHistoric from "../../../components/Table/RowHistoric";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {YELLOW_BG_UX,
		GRAY_LABEL_UX,
		styleDefaultText,
		WHITE_THEME_BLACK,
		ROW_MAIN, 
		BLUE_THEME,
} from "../../../shared/utils";
import Skeleton from "@mui/material/Skeleton";


const Historic = () => {
	const [changeLog, setChangeLog] = useState([]);
	const storeId = useSelector(state => state.StoreReducer.storeId);
	const isOpenHistoric = useSelector(state => state.StoreReducer.openComponent);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const tabs = ["Histórico de Modificações"];
	const dispatch = useDispatch();
	const [selectedTab, setSelectedTab] = useState(0);
	const [loading, setLoading] = useState(true);  
	useEffect(() => {
		sendReport();
	},[selectedTab]);

	const sendReport = () =>{
		setLoading(true);
		const sendData = {
			"loja_id": storeId
		};
		api.sendReport(sendData).then((dataResponse) => {
			setChangeLog(dataResponse.data);
			setLoading(false);
		}).catch(() => {
			setLoading(false);
		});
	}; 

	const handleAbrirTodos = () => {
		dispatch(changeOpenComponent(!isOpenHistoric));
	};

	return(
		<div className="main">
			{loading ? (
				[...Array(15)].map((_, index) => (
					<Skeleton key={index} height={50} />
				))
				) : (
			<Paper sx={{...paperStyleFull, backgroundColor: temaEscuro && BLUE_THEME}}>
			<Tabs value={selectedTab} onChange={(event, newValue) => setSelectedTab(newValue)} aria-label="basic tabs example" indicatorColor="transparent">
				{tabs.map((value, idx) =>{
					return (
						<Tab 
							label={value}
							key={idx}
							sx={{
								borderRadius: "20px",
								color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX,
								backgroundColor: selectedTab === idx ? YELLOW_BG_UX : "transparent",
								"&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
									color: temaEscuro ? ROW_MAIN : GRAY_LABEL_UX,
								},
							}}
						/>
					);
				})}
			</Tabs>
				{selectedTab === 0 && (
					<Grid data-testid="historic" container sx={{padding: 1}}>
						<Grid item xs={12}>
							<h3 style={{...styleDefaultText, color: temaEscuro && WHITE_THEME_BLACK}}>
								Detalhamento do Histórico de Alterações: 
							</h3>
						</Grid>
						<Grid item xs={12} sx={{p: 1, border: "1px solid rgba(224, 224, 224, 1)"}}>
							<TableContainer>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow>
											<TableCell style={{fontWeight: "bold", backgroundColor: temaEscuro && BLUE_THEME}} width="90%" align="left">
												Data
											</TableCell>
											<TableCell style={{fontWeight: "bold", backgroundColor: temaEscuro && BLUE_THEME}} width="5%" align="right">
												Hora
											</TableCell>
											
											<TableCell
												data-testid="open-rows"
												align="right"
												style={{cursor: "pointer", backgroundColor: temaEscuro && BLUE_THEME}}
												width="5%"
												onClick={() => handleAbrirTodos()}
											>
												{isOpenHistoric ? <KeyboardDoubleArrowUpIcon/> : <KeyboardDoubleArrowDownIcon/>}
											</TableCell>
										</TableRow>
									</TableHead>
									
									<TableBody>
										{changeLog.map((item, idx) => (
											<RowHistoric hasMonthYear={true} data={item.data} hora={item.hora} values={item.values} key={`${item.data}-${item.hora}-${idx}`}/>
										)
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
				)}
			</Paper>
			)}
		</div>
	);
};

export default Historic;