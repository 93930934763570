import React, {useState, useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {BLACK_BUTTON, YELLOW_BG_UX} from "../../shared/utils";
import Button from "@mui/material/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";

export default function Cielo() {
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const navigate = useNavigate();
	const location = useLocation();
	const [code, setCode] = useState("");
	const [state, setState] = useState("");

	const host = window.location.protocol + "//" + window.location.host;
	const redirectUri = `${host}/cielo`;

	// eslint-disable-next-line no-undef
	const url = `https://minhaconta2.cielo.com.br/oauth/?mode=redirect&client_id=${process.env.REACT_APP_CIELO_CLIENT_ID}&redirect_uri=${redirectUri}&state=STATE_INFO&scope=profile_read,transaction_read`;


	useEffect(() => {
		getTokens();
	}, []);

	const redirectToCielo = () => {
		window.location.replace(url);
	};

	const getTokens = () => {
		const query = new URLSearchParams(location.search);		
		
		let code = query.get("code");
		let state = query.get("state");
		setCode(code);
		setState(state);
		if (code != null && state != null) {
			navigate("/api", { state: {cieloToken: code, state: state}});
		}
	};


	return (
		<div className="main">
			<Button
				variant="contained"
				onClick={redirectToCielo}
				style={{backgroundColor: temaEscuro ? YELLOW_BG_UX : BLACK_BUTTON}}
			>
				Autenticação Cielo
			</Button>

			<h1>Tokens</h1>
			<Grid container spacing={2} rowSpacing={4} >
				<Grid item xs={6}>
					<Typography sx={{ m:1, fontSize: 20}}>Code: {code}</Typography>
				</Grid>
				<Grid item xs={6}>
					<Button size="small" onClick={() => navigator.clipboard.writeText(code)} style={{color: "black"}}>
						<ContentCopyIcon/>
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Typography sx={{ m:1, fontSize: 20}}>State: {state}</Typography>
				</Grid>
			</Grid>
		</div>

	);
}