import React, {useState, useEffect, useCallback} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import api from "../../../axios";
import Skeleton from "@mui/material/Skeleton";
import SelectStore from "../../../components/SelectStore/SelectStore";
import {
		GRAY_LABEL_UX,
		YELLOW_INFO_UX,
		PURPLE_INFO_UX,
		styleDefaultText,
		WHITE_THEME_BLACK,
		PAPER_PADDING_THEME,
		paperStyle,
	} from "../../../shared/utils";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import {showSnackMessage} from "../../../actions/SnackActions";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import IconButton from "@mui/material/IconButton";
import Tour from "reactour";
import { DASH_BAR_COLOR } from "../../../shared/utils";
import { steps } from "./TourPDV";
import LastModified from "../../../components/LastModified/LastModified";
import FormControl from "@mui/material/FormControl";
import FilterWithTag from "../../../components/Filter/FilterWithTag";
import Box from "@mui/material/Box";
import ConciliacaoAccordion from "../../../components/Table/Conciliacao/ConciliacaoAccordion";
import GenericModal from "../../../components/Modal/GenericModal";
import CircleIcon from "@mui/icons-material/Circle";
import GrayButton from "../../../components/Buttons/GrayButton";
import {useDispatch} from "react-redux";
import Alert from "@mui/material/Alert";

const PDV = () => {
    const [loading, setLoading] = useState(false);
	const [startAt, setStartAt] = useState(0);
	const [estabelecimentos, setEstabelecimentos] = useState([]);
	const [valueEstabelecimentos, setValueEstabelecimentos] = useState([]);
	const [estabelecimentosSelected, setEstabelecimentosSelected] = useState([]);
	// const [plataformasRevisadas, setPlataformasRevisadas] = useState([]);
	const [monthsFiltered, setMonthsFiltered] = useState([]);
	const [monthsAll, setMonthsAll] = useState([]);
    const [rows, setRows] = useState({});
	const [previousMonths, setPreviousMonths] = useState([]);
    const [portais, setPortais] = useState([]);
    const [selectedPortal, setSelectedPortal] = useState([]);
	const [previousPortal, setPreviousPortal] = useState([]);
    const [formasPagamento, setFormasPagamento] = useState([]);
    const [selectedFormaPagamento, setSelectedFormaPagamento] = useState([]);
	const [previousFormaPagamento, setPreviousFormaPagamento] = useState("");
    const [dateFixed, setDateFixed] = useState("");
    const [revisada, setRevisada] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [scrollPosition, setScrollPosition] = useState({});
    const [fieldId, setFieldId] = useState(false);
	const [comment, setComment] = useState("");
	const [fixedValue, setFixedValue] = useState(0);
	const [openModalFixedValue, setOpenModalFixedValue] = useState(false);
	const [commentsList, setCommentsList] = useState([]);
	const [openInsertCommentModal, setOpenInsertCommentModal] = useState(false);
	const [openViewCommentModal, setOpenViewCommentModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [open, setOpen] =  useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [previousValue, setPreviousValue] = useState(0);
    
    const storeId = useSelector(state => state.StoreReducer.storeId);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const isAdmin = useSelector(state => state.AuthReducer.isAdmin);

    const dispatch = useDispatch();

    useEffect(() => {
        if (storeId != 0) {
            getMesesCompetencia();
            getPDV(true);
        }
    }, [storeId]);

    const getMesesCompetencia = () => {
        let dataRequest = {
            lojas: [storeId]
        };

        api.GetMesesCompetencia(dataRequest).then(response => {
            let dataResponse = response.data;
            setMonthsAll(dataResponse);
        }).catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const getPDV = (isChangeStore = false) => {
        setLoading(true);

        const dataRequest = {
            loja_id: storeId,
            months: isChangeStore ? [] : monthsFiltered,
            selected_portal: isChangeStore ? [] : selectedPortal,
            selected_estabelecimentos: isChangeStore ? [] : estabelecimentosSelected,
            selected_forma_pagamento: isChangeStore ? [] : selectedFormaPagamento
        };

        api.GetPDV(dataRequest).then((response) => {
            let dataResponse = response.data;
            setRows(dataResponse.pdv);
            setPortais(dataResponse.portais);
			setPreviousPortal(dataResponse.selected_portal);
            setEstabelecimentos(dataResponse.estabelecimentos);
            setValueEstabelecimentos(dataResponse.selected_estabelecimento);
			setEstabelecimentosSelected(dataResponse.selected_estabelecimento);
            setFormasPagamento(dataResponse.formas_pagamento);
            setPreviousMonths(dataResponse.meses);
            setSelectedPortal(dataResponse.portal);
			setPreviousFormaPagamento(dataResponse.forma_pagamento);
            setLoading(false);
        }).catch((error) => {
			if (error.response.status == 404) {
				dispatch(showSnackMessage({message: error.response.data, severity: "info"}));
			}
			else{
				dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
			}
            setLoading(false);
			setEstabelecimentosSelected([]);
			setEstabelecimentos([]);
			setValueEstabelecimentos([]);
			setPortais([]);
			setFormasPagamento([]);
			setRows([]);
			setMonthsAll([]);
        });
    };

    const handleOpenModal = useCallback((column, periodo, mesConciliacao, isRevisada = false) => {
		const periodoCaptured = rows.find(item => item.periodo === mesConciliacao);
		const findRow = periodoCaptured.data.find(item => item.periodo.value === periodo);

		const periodoValue = findRow.periodo.value;
		const parts = periodoValue.split("/");
		const ano = mesConciliacao.split("/")[1];
		const dia = parts[0];
		const mes = parts[1];
		const dateFixed = `${ano}-${mes}-${dia}`;

		setRevisada(isRevisada);
		setDateFixed(dateFixed);
		setFieldId(column);
		setPreviousValue(findRow[column].value);
		setComment(findRow[column].comments);
		setFixedValue(findRow[column].is_fixed ? findRow[column].value : 0);
		setCommentsList(findRow[column].comments);
		
		if (isAdmin) {
			setOpenModal(true);
		}
	}, [isAdmin, rows]);

    const changeOpen = () => {
        setStartAt(0);
		setOpen(!open);
    };

    const editComment = useCallback((comment, commentId) => {
		setLoadingModal(true);
		setIsEdit(false);
		const dataRequest = {
			comentario: comment,
			comentario_id: commentId
		};
		api.editCommentPDV(dataRequest).then(() => {
			setLoadingModal(false);
			setOpenInsertCommentModal(false);
			dispatch(showSnackMessage({message: "Comentário editado com sucesso"}));
			getPDV();
		}).catch(() => {
			setLoadingModal(false);
			setOpenInsertCommentModal(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	}, []);

    const getValorAntigo = (valorAntigo) => {
		if(valorAntigo === "-"){
			return 0;
		} else if (typeof valorAntigo === "string") {
			return parseFloat(valorAntigo);
		}
	};

    const sendMetadata = useCallback((metadata, fixedValue, comment) => {
		setLoadingModal(true);
		const dataRequest = {
			loja_id: storeId,
			data: dateFixed,
			plataforma: selectedPortal,
			campo: fieldId,
			valor_antigo: getValorAntigo(previousValue), 
			tipo: metadata,
			valor_fixo: fixedValue,
			comentario: comment,
			estabelecimento: estabelecimentosSelected
		};
		api.FixPDVField(dataRequest).then(() => {
			setLoadingModal(false);
			setOpenModalFixedValue(false);
			setOpenInsertCommentModal(false);
			setOpenViewCommentModal(false);
			dispatch(showSnackMessage({message: "Alterações salvas com sucesso"}));
			getPDV();
		}).catch(() => {
			setLoadingModal(false);
			setOpenModalFixedValue(false);
			setOpenInsertCommentModal(false);
			setOpenViewCommentModal(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	}, [storeId, dateFixed, fieldId, previousValue, selectedPortal]);

    const delMetadata = useCallback((type, comentario_id) => {
		setLoadingModal(true);
		const dataRequest = {
			loja_id: storeId,
			data: dateFixed,
			plataforma: selectedPortal,
			campo: fieldId,
			tipo: type,
			comentario_id,
			estabelecimento: estabelecimentosSelected
		};

		api.DeletePDVField(dataRequest).then(() => {
			setLoadingModal(false);
			setOpenModalFixedValue(false);
			setOpenViewCommentModal(false);
			dispatch(showSnackMessage({message: "Deletado com sucesso"}));
			getPDV();
		}).catch(() => {
			setLoadingModal(false);
			setOpenModalFixedValue(false);
			setOpenViewCommentModal(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	}, [storeId, dateFixed, fieldId, selectedPortal]);

    const emptyFunction = useCallback(() => null, []);

    return (
        <div className="main">
            {
				!!isAdmin && (
					<React.Fragment>
						<GenericModal
							isConciliacao
							openModal={openModal}
							dateFixed={dateFixed}
							setOpenModal={setOpenModal}
							fixedValue={fixedValue}
							setFixedValue={setFixedValue}
							setOpenModalFixedValue={setOpenModalFixedValue}
							openModalFixedValue={openModalFixedValue}
							openViewCommentModal={openViewCommentModal}
							openInsertCommentModal={openInsertCommentModal}
							setOpenViewCommentModal={setOpenViewCommentModal}
							setOpenInsertCommentModal={setOpenInsertCommentModal}
							commentsList={commentsList}
							comment={comment}
							handleDetalharValor={emptyFunction}
							setComment={setComment}
							editComment={editComment}
							loadingModal={loadingModal}
							delMetadata={delMetadata}
							sendMetadata={sendMetadata}
							isEdit={isEdit}
							setIsEdit={setIsEdit}
							fieldId={fieldId}
							component="conciliacao"
							revisada={revisada}
							estabelecimentosSelected={estabelecimentosSelected}
						/>
					</React.Fragment>
				)
			}
            {storeId == 0 ? (
                <SelectStore/>
            ) : (
                loading ? (
                    <React.Fragment>
						<div style={{display: "flex"}}>
							{
								[...Array(2).keys()].map((i,d) =>{
									return(
										<Skeleton sx={{marginRight: 5}} key={d} height={50} width={180}/>
									);
								})
							}
						</div>
						<div style={{display: "flex"}}>
							{
								[...Array(5).keys()].map((i,d) =>{
									return(
										<Skeleton sx={{marginRight: 5}} key={d} height={50} width={120}/>
									);
								})
							}
						</div>
						{
							[...Array(10).keys()].map((i,d) =>{
								return(
									<Skeleton height={50} key={d}/>
								);
							})
						}
					</React.Fragment>
                ) : (
                    <React.Fragment>
                        <LastModified title="PDV"/>
						<Alert variant="outlined" severity="warning" sx={{marginBottom: 2}}>
							Atenção! A partir de janeiro de 2025, o PDV será descontinuado.
						</Alert>
                        <Paper sx={{...paperStyle,  backgroundColor: temaEscuro && PAPER_PADDING_THEME, backgroundImage: temaEscuro && "none"}} data-testid="pdv-table">
                            <Grid container sx={{display: "flex", alignItems: "center", marginBottom: 1, width: "100%", gap: 2}}>
                                <Grid item>
                                    <Grid container sx={{justifyContent: "start", display: "flex"}}>
                                        <FormControl className="filter-estabelecimentos" >
                                            <FilterWithTag
                                                options={estabelecimentos}
                                                setOptions={setEstabelecimentosSelected}
                                                testIdAll={""}
                                                previousValue={valueEstabelecimentos}
                                                optionFilter
                                                handleSubmit={null}
                                                monthsFiltered={null}
                                                limitSelection={null}
                                                testId={"filter-estabelecimentos"}
												widthSetting={"395px"}
												placeholderProp="Estabelecimentos"
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item className="filter-date" >
                                    <FilterWithTag
                                        isConciliacao
                                        optionFilter
                                        data-testid="filter-with-tag"
                                        options={monthsAll}
                                        setOptions={setMonthsFiltered}
                                        previousValue={previousMonths}
										widthSetting={"350px"}
										placeholderProp="Meses"
                                    />
                                </Grid>
                                <Grid item sx={{display: "flex", gap: 2, flexWrap: "wrap"}}>
									<FilterWithTag
										options={portais}
										setOptions={setSelectedPortal}
										previousValue={previousPortal}
										optionFilter
										testId={"filter-portais"}
										widthSetting={"350px"}
										placeholderProp="Portais"
									/>
									<FilterWithTag
										options={formasPagamento}
										setOptions={setSelectedFormaPagamento}
										previousValue={previousFormaPagamento}
										optionFilter
										testId={"filter-portais"}
										widthSetting={"350px"}
										placeholderProp="F. de Pagamento (PDV)"
									/>
                                </Grid>
								<Grid item>
									<GrayButton
										onClick={() => getPDV()}
										className="enviar-pdv-btn"
										title="filtrar"
										startIcon={<FilterListOutlinedIcon/>}
										testId="filtrar"
									/>
								</Grid>
								<Grid item xs sx={{display: "flex", justifyContent: "end"}}>
									<IconButton data-testid="tutorial-pdv" style={{background: "#ECC94B", borderRadius: "4px"}} onClick={() => changeOpen()}>
										<LightbulbIcon style={{color: GRAY_LABEL_UX, fontSize: "20px", padding: "1px"}}/>
									</IconButton>
                                </Grid>
                            </Grid>
                            <Grid container>
								<Grid item xs={4} sx={{marginTop: 2, marginBottom: 1}}>
									<Grid container spacing={2}>
										<Grid item>
											<Grid container sx={{display: "flex", alignItems: "center", gap: 1, justifyContent: "center"}}>
												<Grid item sx={{display: "flex", alignItems: "center"}}>
													<CircleIcon className="circles" style={{ color: YELLOW_INFO_UX }}/>
												</Grid>
												<Grid item sx={{...styleDefaultText, display: "flex", alignItems: "center", color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
													<a>Valor Manual</a>
												</Grid>
											</Grid>
										</Grid>
										<Grid item>
											<Grid container sx={{display: "flex", alignItems: "center", gap: 1, justifyContent: "center"}}>
												<Grid item sx={{display: "flex", alignItems: "center"}}>
													<CircleIcon className="circles" style={{ color: PURPLE_INFO_UX, }}/>
												</Grid>
												<Grid item sx={{...styleDefaultText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
													<a>Comentário</a>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={6}>
									<Tour
										steps={steps}
										isOpen={open}
										onRequestClose={changeOpen}
										accentColor={DASH_BAR_COLOR}
										startAt={startAt}
										rounded={5}
										showNavigation={false}
										inViewThreshold={60}
										className={temaEscuro && "tour"}
										badgeContent={(curr, tot) => `${curr}/${tot}`}
									/>
								</Grid>
							</Grid>
							<Box sx={{ width: "100%", marginTop: 2}} className="all-conciliacao-accordions" >
								<ConciliacaoAccordion
									isPDV
									rows={rows}
									setLoading={setLoading}
									handleOpenModal={handleOpenModal}
									// plataformasRevisadas={plataformasRevisadas}
									plataformasRevisadas={[]}
									selectedTab={[]}
									openConciliacaoModal={openModal}
									setOpenConciliacaoModal={setOpenModal}
									openModal={openModal}
									setScrollPosition={setScrollPosition}
									scrollPosition={scrollPosition}
									selectedPortal={selectedPortal}
									monthsFiltered={previousMonths}
									estabelecimentosSelected={estabelecimentosSelected}
									selectedFormaPagamento={selectedFormaPagamento}
								/>
							</Box>
                        </Paper>
                    </React.Fragment>
                )
            )}
        </div>
    );
};

export default PDV;