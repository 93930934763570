import React, { useState, useEffect }  from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {GRAY_LABEL_UX, WHITE_TABLE, formatterCurrency} from "../../shared/utils";
import "./Table.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import RowDetailHistoric from "./RowDetailHistoric";

const RowHistoric = (props) => {
	const {data, hora, values} = props;

	const [open, setOpen] = useState(true);
	const [openDetail, setOpenDetail] = useState(false);

	const openComponent = useSelector(state => state.StoreReducer.openComponent);
	const columns = [
		"Campo alterado",
		"Valor anterior",
		"Novo valor",
		"Usuário",
		"Mês",
		"Ano"
	];

	useEffect(() => {
		setOpen(openComponent);
		setOpenDetail(openComponent);
	}, [openComponent]);

	return(
		<React.Fragment>
			<TableRow className="table-row" hover role="checkbox">
				<TableCell
                    align="left"
					style={styles.tableCell}
				>
					{data}
				</TableCell>
                <TableCell
                    align="right"
					style={styles.tableCell}
				>
					{hora}
				</TableCell>
                <TableCell data-testid="open-row" align="right" style={{"cursor": "pointer"}} onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </TableCell>
			</TableRow>
            {open && (
                <TableRow className="table-row" hover role="checkbox">
					<TableCell align="left" width="90%" colSpan={3}>
						<TableContainer className="table-container">
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										{columns.map((column, index) => (
											<TableCell key={index} align="center">{column}</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{values.map((row, idx) => (
										<React.Fragment key={`${row.altered_field}-${row.previous_value}-${row.new_value}-${idx}`}>
											<TableRow>
												<TableCell align="center">{row.altered_field}</TableCell>
												<TableCell align="center">{formatterCurrency(row.previous_value)}</TableCell>
												<TableCell align="center">{formatterCurrency(row.new_value)}</TableCell>
												<TableCell align="center">{row.user}</TableCell>
												<TableCell align="center">{row.month}</TableCell>
												<TableCell align="center">{row.year}</TableCell>
												{row?.detailed_log && (
													<TableCell data-testid="open-row" align="right" style={{"cursor": "pointer"}} onClick={() => setOpenDetail(!openDetail)}>
														{openDetail ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
													</TableCell>
												)}
											</TableRow>
											{(row?.detailed_log && openDetail) && (												
												<RowDetailHistoric rows={row.detailed_log} />
											)}
										</React.Fragment>
									))
									}
								</TableBody>
							</Table>
						</TableContainer>
					</TableCell>
                </TableRow>
            )}
		</React.Fragment>
	);
};

const styles = {
	tableCell: {
		backgroundColor: GRAY_LABEL_UX,
		fontWeight: "bold",
		color: WHITE_TABLE,
		position: "sticky",
		left: 0,
		zIndex: 1,
	}
};

export default RowHistoric;