import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import {
    smallPaperStyle,
    BLUE_THEME,
    styleGrid,
    styleText,
    WHITE_THEME_BLACK,
    styleTitle,
} from "../../shared/utils";
import YellowButton from "../Buttons/YellowButton";
import GrayButton from "../Buttons/GrayButton";
import SendIcon from "@mui/icons-material/Send";
import UpdateIcon from "@mui/icons-material/Update";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {showSnackMessage} from "../../actions/SnackActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import api from "../../axios";
import Header from "../Titles/Header";


const SaveDRE = (props) => {
    const {
        openSaveDre, setOpenSaveDre, selectedColumn, handleSaveDRE, sendDreSaved, getDRE, monthsFiltered
    } = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const isOperator = useSelector(state => state.AuthReducer.isOperator);
    const isManager = useSelector(state => state.AuthReducer.isManager);
    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const storeId = useSelector(state => state.StoreReducer.storeId);
    const [openSendDREType, setOpenSendDREType] = useState(false);
    const [optionSelected, setOptionSelected] = useState(null);
    const [reuniaoFeita, setReuniaoFeita] = useState(false);

    const dispatch = useDispatch();

    const checkSendOption = () => {
        if (optionSelected != null) {
            if (optionSelected == "relatorio") {
                sendDreSaved();
                handleCloseAllStates();
            }
            else if (optionSelected == "reuniao") {
                let dataRequest = {
                    loja_id: storeId,
                    data: selectedColumn.id,
                    tipo: optionSelected,
                    status: reuniaoFeita
                };

                api.SendDREMeeting(dataRequest).then(() => {
                    getDRE(monthsFiltered);
                    handleCloseAllStates();
                }).catch(() => {
                    dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
                });
            }
        }
    };

    const handleMostrarAtualizaçõesDRE = () => {
        const url = "/dre_atualizada";
        const params = {loja_id: storeId, selected_date: selectedColumn.id};
        const queryString = new URLSearchParams(params).toString();
        const fullUrl = `${url}?${queryString}`;
        window.open(fullUrl, "_blank");
    };

    const handleRadioOptionChange = (event) => {
        setOptionSelected(event.target.value);
    };

    const handleCloseAllStates = () => {
        setReuniaoFeita(false);
        setOptionSelected(null);
        setOpenSendDREType(false);
        setOpenSaveDre(false);
    };

    return(
        <React.Fragment>
            <Modal
                open={openSaveDre}
                onClose={() => handleCloseAllStates()}
                aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
                data-testid="save-dre-modal"
            >
                {openSendDREType ? (
                    <Paper style={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME}}>
                        <Grid container sx={{display: "flex", flexDirection: "column"}}>
                            <Grid item xs={10} className="start">
                                <h3 style={{...styleTitle, width: "auto", color: temaEscuro && WHITE_THEME_BLACK}}>Enviar DRE:</h3>
                            </Grid>
                            <Grid item>
                                <span 
                                    style={{ ...styleText, fontSize: "18px", color: temaEscuro && WHITE_THEME_BLACK }}
                                >
                                    <p>
                                        Escolha como deseja apresentar a revisão da DRE: <b>{selectedColumn.label}</b>:
                                    </p>
                                </span>
                            </Grid>
                            <Grid item>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={optionSelected}
                                    onChange={handleRadioOptionChange}
                                >
                                    <FormControlLabel value="reuniao" control={<Radio />} label="Reunião" />
                                    {optionSelected == "reuniao" && (
                                        <Box sx={{display: "flex", gap: 1, ml: "2rem"}}>
                                            <p>Status:</p>
                                            <FormControlLabel
                                            value="end"
                                            control={<Checkbox />}
                                            onChange={(event) => setReuniaoFeita(event.target.checked)}
                                            label={reuniaoFeita ? "Feita" : "Pendente"}
                                            labelPlacement="end"
                                        />
                                        </Box>
                                    )}
                                    <FormControlLabel value="relatorio" control={<Radio />} label="Relatório" />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container sx={{display: "flex", justifyContent: "end"}}>
                                    <Grid item sx={styleGrid}>
                                        <GrayButton
                                            onClick={() => {
                                                setReuniaoFeita(false);
                                                setOptionSelected(null);
                                                setOpenSendDREType(false);
                                            }}
                                            title="Voltar"/>
                                    </Grid>
                                    <Grid item sx={styleGrid}>
                                        <YellowButton
                                        onClick={() => checkSendOption()}
                                            testId="save-dre-confirm-btn"
                                            title={"Enviar"}
                                            startIcon={<SendIcon/>}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                ) : (
                    <Paper style={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME}}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} className="start">
                                <Header title="Salvar DRE:" />
                            </Grid>
                            <Grid item>
                                {(selectedColumn?.revisada || selectedColumn?.pre_revisada) ? (
                                    <span 
                                        style={{ ...styleText, fontSize: "18px", color: temaEscuro && WHITE_THEME_BLACK }}
                                    >
                                        {(isOperator && (!isAdmin && !isManager)) ? (
                                            <p>
                                                Deseja deletar a pré-revisão da DRE: <b>{selectedColumn.label}</b>?
                                            </p>
                                        ) : (
                                            selectedColumn?.pre_revisada ? (
                                                <p>
                                                    Escolha o que deseja fazer com a pré-revisão da DRE: <b>{selectedColumn.label}</b>
                                                </p>
                                            ) : (
                                                <p>
                                                    Escolha o que deseja fazer com a revisão da DRE: <b>{selectedColumn.label}</b>
                                                </p>
                                            )
                                        )}
                                    </span>
                                    ) : (
                                    <span
                                        style={{ ...styleText, fontSize: "18px", color: temaEscuro && WHITE_THEME_BLACK }}
                                    >
                                        {(isOperator && (!isAdmin && !isManager)) ? (
                                            <p>
                                                Tem certeza que deseja pré-salvar a DRE: <b>{selectedColumn.label}</b>
                                            </p>
                                        ) : (
                                            <p>
                                                Tem certeza que deseja salvar a DRE: <b>{selectedColumn.label}</b>
                                            </p>
                                        )}
                                    </span>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <GrayButton
                                                    onClick={() => setOpenSaveDre(false)}
                                                    title="Cancelar"/>
                                            </Grid>
                                            {
                                                (selectedColumn?.revisada || selectedColumn?.pre_revisada) && (
                                                    <Grid item>
                                                        <GrayButton
                                                            startIcon={<UpdateIcon/>}
                                                            onClick={() => handleMostrarAtualizaçõesDRE()}
                                                            title="Mostrar Atualizações"/>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                        <Grid item xs={6}>
                                            <Grid container spacing={2} sx={{display: "flex", justifyContent: "end"}}>
                                                {(selectedColumn?.revisada && !isManager) && (
                                                    <Grid item>
                                                        <GrayButton
                                                            startIcon={<SendIcon/>}
                                                            onClick={() => setOpenSendDREType(true)}
                                                            title="Enviar DRE"/>
                                                    </Grid>
                                                )}
                                                <Grid item>
                                                    {(isOperator && (!isAdmin && !isManager)) ? (
                                                        <YellowButton
                                                            onClick={() => {
                                                                selectedColumn?.pre_revisada ? handleSaveDRE(selectedColumn, "remove_pre_salvar") : handleSaveDRE(selectedColumn, "pre_salvar");
                                                            }}
                                                            testId="pre-save-dre-confirm-btn"
                                                            title={selectedColumn?.pre_revisada ? "Deletar" : "Confirmar"}
                                                        />
                                                    ) : (
                                                        <YellowButton
                                                            onClick={() => {
                                                                selectedColumn?.revisada ?
                                                                handleSaveDRE(selectedColumn, "remove_salvar") :
                                                                handleSaveDRE(selectedColumn, "salvar");
                                                            }}
                                                            testId="save-dre-confirm-btn"
                                                            title={selectedColumn?.revisada ? "Deletar" : "Confirmar"}
                                                        />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                )}

            </Modal>
        </React.Fragment>
    );
};

export default React.memo(SaveDRE);