import React from "react";
import GrayButton from "../Buttons/GrayButton";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {useSelector} from "react-redux";
import TextField from "@mui/material/TextField";
import UploadIcon from "@mui/icons-material/Upload";
import { 
    paperDash,
    BLUE_THEME,
    RED_ERROR_UX,
    BORDER_BUTTON,
    translateAutocomplete,
    WHITE_TABLE,
    GRAY_BG_UX,
    textModificacao,
} from "../../shared/utils";
import api from "../../axios";
import {useDispatch} from "react-redux";
import {showSnackMessage} from "../../actions/SnackActions";
import Autocomplete from "@mui/material/Autocomplete";


export default function ImageButtonCard(props) {
    const {img, plataforma, estabelecimentos} = props;
    const dispatch = useDispatch();

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const storeId = useSelector(state => state.StoreReducer.storeId);
    const [idPlataforma, setIdPlataforma] = React.useState(plataforma === "Cielo" ? props.cieloToken : "");
    const [numero, setNumero] = React.useState("");
    const [inputError, setInputError] = React.useState(false);
    const [estabelecimento, setEstabelecimento] = React.useState("");

    const handleChange = (e) => {
        // Get the input value
        const value = e.target.value;
    
        // Check if the value is a valid integer (positive or negative)
        if (/^-?\d+$/.test(value) || value === "") {
            setNumero(value);
        }
    };

    const postRegisterApis = () => {
        if (idPlataforma === "" || estabelecimento === "") {
            setInputError(true);
            return;
        }
        const data = {
            loja_plataforma_id: idPlataforma,
            loja_id: storeId,
            numero: numero === "" ? 1 : numero,
            plataforma,
            estabelecimento: estabelecimento
        };
        api.PostRegisterAPI(data).then((response) => {
            if (response.status === 201) {
                dispatch(showSnackMessage({message: response.data.message, severity: "success"}));
            } else {
                dispatch(showSnackMessage({message: response.data.message, severity: "warning"}));
            }
        }).catch(() => {
            dispatch(showSnackMessage({message: "Erro ao cadastrar API!", severity: "error"}));
        });
    };

    const handleChangeEstabelecimento = (obj) => {
		setEstabelecimento(obj.value);
	};

    return (
        <React.Fragment>
            <Paper data-testid={`${plataforma}-image-button-card`} sx={{...paperDash, maxWidth: "250px", backgroundColor:temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{display: "flex", justifyContent: "center"}}>
                            <img src={img} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {
                                props.cieloToken !== "" && (
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <TextField
                                                    data-testid="id-estabelecimento-input"
                                                    margin="normal"
                                                    label="Id estabelecimento"
                                                    autoComplete="Id estabelecimento"
                                                    onChange={(e) => setIdPlataforma(e.target.value.trim())}
                                                    value={idPlataforma}
                                                    variant="outlined"
                                                    error={(inputError && idPlataforma === "")}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: temaEscuro && idPlataforma === "" && BORDER_BUTTON
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            {
                                                (inputError && estabelecimento === "") &&
                                                <p data-testid="email-error" style={{color: RED_ERROR_UX, fontSize: "12px"}}>*Campo(s) obrigatório(s)</p>
                                            }
                                            <Grid item sx={{width: "100%"}}>
                                                <Autocomplete
                                                    size="small"
                                                    noOptionsText={translateAutocomplete} // <= componente importado do arquivo utils.js 
                                                    data-testid="select-estabelecimento"
                                                    disablePortal
                                                    disableClearable
                                                    onChange={(event, newValue) => {
                                                        handleChangeEstabelecimento(newValue);
                                                    }}
                                                    PaperComponent={({ children }) => (
                                                        <Paper
                                                            style={{
                                                                backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
                                                                color: temaEscuro ? "#FFF" : "#4A5568",
                                                                fontWeight: "500"
                                                            }}
                                                        >
                                                            {children}
                                                        </Paper>
                                                    )}
                                                    options={estabelecimentos}
                                                    sx={{backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE}}
                                                    renderInput={(params) =>
                                                        <TextField {...params} 
                                                        label="Estabelecimento" 
                                                        InputLabelProps={{
                                                            style: {color: temaEscuro && params.inputProps.value === "" && BORDER_BUTTON}}}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    type="text"
                                                    data-testid="numero-login-input"
                                                    margin="normal"
                                                    label="Número"
                                                    onChange={handleChange}
                                                    value={numero}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        style: {
                                                            color: temaEscuro && idPlataforma === "" && BORDER_BUTTON
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }
                            <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                {
                                    plataforma === "Cielo" ? (
                                        props.cieloToken === "" ? (
                                            <GrayButton isRedirect path="/cielo" title="Cadastrar" testId="cadastrar-button" startIcon={<UploadIcon />} />

                                        ) : (
                                            <GrayButton onClick={() => postRegisterApis()} title="Cadastrar" testId="cadastrar-button" startIcon={<UploadIcon />} />

                                        )
                                    ) : (
                                        <GrayButton testId="cadastrar-button" onClick={() => postRegisterApis()} title="Cadastrar" startIcon={<UploadIcon />} />
                                    )
                                }
                            </Grid>
                            {(plataforma === "Cielo" && props.cieloToken === "") && (
                                <Grid item xs={12} sx={{display: "flex", alignItems: "center"}}>
                                    <a style={{...textModificacao, marginBottom: 0}}>Aviso: Ler documentação para cadastrar</a>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
}
